import type { GetStaticPropsContext, GetStaticPropsResult } from 'next/types'
import type {
  AllPagesQuery,
  FrontPageQuery,
  FrontPageQueryVariables,
} from '@/.generated/sanityTypes/graphql'
import type { PreviewData } from '@monorepo/helper/types'

import React from 'react'
import { useTranslations } from 'next-intl'
import { frontPage as frontPageQuery } from './index.sanity.graphql'
import { request } from '@/lib/sanityClient'

import Layout from '@/components/layout'
import PageSection from '@/components/pageSection'
import { getAllPages } from '@/lib/query'

type HomeProps = {
  data: FrontPageQuery
  messages: unknown
  isPreview: boolean
  allPages: AllPagesQuery
}

export default function Home({ data, isPreview, allPages }: HomeProps) {
  const t = useTranslations()

  return (
    <Layout
      allPages={allPages}
      isPreview={isPreview}
      noPadTop
      title={t('menu.home')}
    >
      {data?.allFrontPage[0].sections?.map((section) => {
        return (
          <PageSection
            //@ts-expect-error 2339
            key={section?._key || section?._id}
            data={section}
            rootData={data}
          />
        )
      })}
    </Layout>
  )
}

export async function getStaticProps(
  context: GetStaticPropsContext,
): Promise<GetStaticPropsResult<HomeProps>> {
  const isPreview = context.preview || false
  let pageId = 'frontPage'
  if (isPreview) {
    const previewData = context.previewData as PreviewData
    if (previewData?.previewItem?.id.startsWith('drafts.')) {
      pageId = previewData.previewItem.id
    }
  }
  const queryVariables = {
    where: {
      _id: {
        eq: pageId,
      },
    },
  }

  const pageData = await request<FrontPageQuery, FrontPageQueryVariables>(
    frontPageQuery,
    queryVariables,
    isPreview,
  )

  if (pageData.errors)
    throw new Error(`graphql Error occured: ${pageData.errors[0].message}`)

  return {
    props: {
      data: pageData.data,
      messages: (await import(`@/translation/${context.locale}.json`)).default,
      isPreview,
      allPages: await getAllPages(),
    },
  }
}
